<template>
    <v-layout column fill-height>
        <router-view></router-view>
    </v-layout>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";
import NavDrawer from '@/components/SuperAdmin/Support/NavDrawer'

import Vue from 'vue'

export default {
	name: 'SuperAdminSupport',
	mixins: [
		SuperAdminModuleGuard,
		HandleSideBarMixin,
	],
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
		this.setHasSidebar(false)
		this.setPermanentSidebar(false)
	},
	mounted: function() {
		this.mountNavDrawer()
	},
	methods: {
		mountNavDrawer: function() {
			this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			const NavDrawerClass = Vue.extend(NavDrawer)
			const navDrawerComponent = new NavDrawerClass({
				parent: this
			})
			this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
				canMinimize: false,
				startHidden: false,
				canClose: false,
				title: this.$t('sa.support.available_actions'),
				moduleInstance: navDrawerComponent,
				emitter: this
			})
			this.setHasSidebar(true)
			this.setPermanentSidebar(true)
		},
	}
}
</script>
